import { atom } from 'jotai';

export const inStableFilterTypes = [
  { key: 'all', value: 'すべて' },
  { key: 'in', value: '在厩' },
  { key: 'out', value: '非在厩' },
];

export const horseSearchKeywordAtom = atom<string>('');
export const horseInStableFilterAtom = atom<string>(inStableFilterTypes[0].key);
