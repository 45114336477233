import { Link } from 'react-router';
import { ManageStatus } from '@schema/gen/trainers/models/horse_pb';
import { Spacer } from '@/components/spacer';
import HorseDefault from '@/images/horse_default.svg';
import IconArrowRight from '@/images/icons/icon_arrow_right.svg?react';
import IconEdit from '@/images/icons/icon_edit.svg?react';

export const HorseCard = ({
  horseId,
  horseName,
  horseGender,
  horseAge,
  horseInStable,
  manageStatus,
  profilePicPath,
  lastReportSentAt,
  hasHnContract,
  hasOrmContract,
}: {
  horseId: bigint;
  horseName: string;
  horseGender: string;
  horseAge: number;
  horseInStable: boolean;
  manageStatus: ManageStatus;
  profilePicPath?: string | null;
  lastReportSentAt?: string | null;
  hasHnContract: boolean;
  hasOrmContract: boolean;
}) => {
  const imageUrl = profilePicPath || HorseDefault;
  const horseOwnersPageLink = `/horses/${horseId}/owners`;
  const horseReportsPageLink = `/horses/${horseId}/reports`;
  const horseDashboardLink = `/horses/${horseId}/dashboard`;

  return (
    <div
      className={`flex w-full flex-col gap-2 rounded-[8px] ${manageStatus === ManageStatus.MANAGED ? 'bg-white' : 'bg-[#DAD9DE]'} p-2 sm:h-[64px] sm:flex-row sm:justify-between`}
    >
      <div className="flex w-full items-start gap-2 sm:items-center sm:justify-between">
        <img
          alt="馬のプロフィール画像"
          className="border-border h-[48px] w-[48px] rounded-[8px] border-1"
          src={imageUrl}
        />
        <div className="flex flex-1 flex-col justify-between gap-[4px]">
          <div className="flex items-center gap-1">
            <div className="text-[16px] leading-[22.4px] font-semibold tracking-[1.28px] max-sm:max-w-[180px] sm:leading-[18px]">
              {horseName}
            </div>
            <Spacer horizontal size={4} />
            <div className="border-active bg-item-active flex h-[18px] w-[40px] items-center justify-center gap-[2px] rounded-[9px] border">
              <div className="text-active text-center text-[11px] leading-[11px] font-medium tracking-[0.44px]">
                {horseGender === 'male'
                  ? '牡'
                  : horseGender === 'female'
                    ? '牝'
                    : horseGender === 'gelding'
                      ? 'セン'
                      : ''}
              </div>
              <div className="flex items-start justify-start gap-[10px] pt-[1px]">
                <div className="text-active text-[11px] leading-[11px] font-medium tracking-[1.3px]">
                  {horseAge}
                </div>
              </div>
            </div>
            <div
              className={`flex h-[18px] w-[48px] items-center justify-center rounded-[9px] ${
                horseInStable && manageStatus === ManageStatus.MANAGED
                  ? 'bg-active'
                  : 'bg-[#B4B4B4]'
              }`}
            >
              <div className="text-glyphs-reverse text-center text-[11px] leading-[14px] font-bold tracking-[0.44px]">
                {manageStatus === ManageStatus.UNMANAGED
                  ? '管理外'
                  : horseInStable
                    ? '在厩'
                    : '非在厩'}
              </div>
            </div>
            <Link
              className="text-glyphs-tint ml-2 flex items-center"
              to={`/horses/${horseId}/edit`}
            >
              <IconEdit height={16} width={16} />
            </Link>
          </div>
          {hasOrmContract && (
            <div className="flex w-full flex-wrap items-center justify-between gap-y-2">
              <div className="text-glyphs-shade text-[12px] font-normal">
                最終レポート日時：{lastReportSentAt ?? '未送信'}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="flex items-center justify-between gap-1 sm:justify-end sm:gap-2">
        {hasOrmContract && (
          <div className="flex gap-2">
            <Link
              className="border-border flex h-[32px] items-center rounded-[4px] border-1 px-[14px]"
              prefetch="intent"
              to={horseOwnersPageLink}
            >
              <div className="text-glyphs-normal text-[14px] leading-[14px] font-medium tracking-[0.56px] whitespace-nowrap">
                馬主を管理
              </div>
            </Link>
            <Link
              className="border-border flex h-[32px] items-center rounded-[4px] border-1 px-[14px]"
              prefetch="intent"
              to={horseReportsPageLink}
            >
              <div className="text-glyphs-normal text-[14px] leading-[14px] font-medium tracking-[0.56px] whitespace-nowrap">
                レポートを見る
              </div>
            </Link>
          </div>
        )}
        {hasHnContract && (
          <Link
            className="text-active sm:text-glyphs-normal flex h-[32px] items-center rounded-[4px]"
            prefetch="intent"
            to={horseDashboardLink}
          >
            <div className="text-[12px] leading-[12px] font-normal tracking-[0.4px] whitespace-nowrap sm:text-[14px] sm:leading-[14px] sm:font-medium">
              ダッシュボード
            </div>
            <IconArrowRight className="h-3 w-3" />
          </Link>
        )}
      </div>
    </div>
  );
};
