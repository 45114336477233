import { Button } from '@mantine/core';
import { useAtom } from 'jotai';
import React, { Suspense } from 'react';
import { Await, Link, Outlet } from 'react-router';
import type { Horse } from '@/api_clients/models';
import {
  horseInStableFilterAtom,
  horseSearchKeywordAtom,
  inStableFilterTypes,
} from '@/atoms/horse_filter';
import { Loading } from '@/components/loading';
import { Spacer } from '@/components/spacer';
import IconPlus from '@/images/icons/icon_plus.svg?react';
import IconSearch from '@/images/icons/icon_search.svg?react';
import { normalizeString } from '@/utils/string_utils';
import { HorseCard } from './horse_card';

export function HorseListPage({
  horses,
  hasHnContract,
  hasOrmContract,
}: {
  horses: Horse[];
  hasHnContract: boolean;
  hasOrmContract: boolean;
}) {
  const [searchKeyword, setSearchKeyword] = useAtom(horseSearchKeywordAtom);
  const [selectedInStable, setSelectedInStable] = useAtom(horseInStableFilterAtom);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchKeyword(value);
  };

  const handleInStableChange = (key: string) => {
    setSelectedInStable(key);
  };

  return (
    <div className="flex justify-center">
      <div className="h-full w-full max-w-[900px] flex-col px-4 lg:px-8">
        <div className="flex items-start justify-between pt-4 leading-snug whitespace-nowrap">
          <div className="flex w-full items-center justify-between">
            <div className="text-glyphs-normal text-base font-semibold tracking-wider">所属馬</div>
            <Link className="lg:hidden" to="/horses/add">
              <Button
                className="text-glyphs-normal rounded-sm bg-transparent py-1 pr-2 pl-1 text-[14px] leading-[14px] font-medium tracking-wider"
                color="#CFCFE5"
                variant="default"
              >
                <IconPlus className="aspect-square w-6 shrink-0 self-stretch object-contain" />
                追加
              </Button>
            </Link>
          </div>
        </div>
        <Spacer size={20} />
        <div className="bg-surface-base sticky top-0 flex flex-wrap items-center justify-between gap-x-4 gap-y-2 py-1">
          <div className="flex flex-1 flex-wrap gap-x-4 gap-y-2">
            <div className="outline-border focus-within:outline-active flex h-[40px] max-w-[320px] flex-1 items-center gap-1 rounded-sm bg-white px-3 text-base tracking-wider outline-1">
              <IconSearch className="aspect-square w-[18px] shrink-0" />
              <input
                className="min-w-0 flex-1 focus:outline-hidden"
                id="search_keyword"
                name="search_keyword"
                placeholder="馬名で検索"
                type="text"
                onChange={handleSearchChange}
              />
            </div>
            <div className="outline-border flex h-[40px] w-[240px] gap-1 rounded-sm bg-white p-1 text-center outline-1 outline-solid">
              {inStableFilterTypes.map((inStableFilterType) => (
                <div key={inStableFilterType.key} className="w-[76px]">
                  <label
                    className="text-sm font-medium"
                    htmlFor={'partnerNumber-' + inStableFilterType.key}
                  >
                    <div className="hover:bg-sub-active/50 has-checked:bg-sub-active has-checked:outline-active relative flex h-[32px] items-center justify-center rounded-sm bg-transparent text-white outline-transparent has-checked:outline-1">
                      <input
                        className="absolute appearance-none"
                        defaultChecked={selectedInStable === inStableFilterType.key}
                        id={'partnerNumber-' + inStableFilterType.key}
                        name="inStable"
                        type="radio"
                        value={inStableFilterType.key}
                        onChange={() => handleInStableChange(inStableFilterType.key)}
                      />
                      <div className="text-glyphs-normal">{inStableFilterType.value}</div>
                    </div>
                  </label>
                </div>
              ))}
            </div>
          </div>
          <Link className="hidden lg:block" to="./horses/add">
            <Button
              className="text-glyphs-normal rounded-sm bg-transparent px-4 py-2 text-center text-base font-medium tracking-wider"
              color="#CFCFE5"
              variant="default"
            >
              所属馬追加
            </Button>
          </Link>
        </div>
        <Spacer size={12} />
        <div className="flex flex-col gap-3">
          <Suspense fallback={<Loading />}>
            <Await resolve={horses}>
              {(horses) => (
                <HorseList
                  horses={horses}
                  searchKeyword={searchKeyword}
                  selectedInStable={selectedInStable}
                  hasHnContract={hasHnContract}
                  hasOrmContract={hasOrmContract}
                />
              )}
            </Await>
          </Suspense>
          <Spacer size={120} />
        </div>
      </div>
      <Outlet />
    </div>
  );
}

const HorseList = ({
  horses,
  searchKeyword,
  selectedInStable,
  hasHnContract,
  hasOrmContract,
}: {
  horses: Horse[];
  searchKeyword: string;
  selectedInStable: string;
  hasHnContract: boolean;
  hasOrmContract: boolean;
}) => {
  const filteredHorses = horses.filter((horse) => {
    const normalizedHorseName = normalizeString(horse.name);
    const normalizedSearchKeyword = normalizeString(searchKeyword);
    return (
      normalizedHorseName.includes(normalizedSearchKeyword) &&
      (selectedInStable === 'all' || horse.inStable === (selectedInStable === 'in'))
    );
  });
  return (
    <>
      {filteredHorses.map((horse) => (
        <HorseCard
          key={horse.horseId}
          horseAge={horse.age}
          horseGender={horse.gender}
          horseId={horse.horseId}
          horseInStable={horse.inStable}
          manageStatus={horse.manageStatus}
          horseName={horse.name}
          lastReportSentAt={horse.lastReportSentAt}
          profilePicPath={horse.profilePicPath}
          hasHnContract={hasHnContract}
          hasOrmContract={hasOrmContract}
        />
      ))}
    </>
  );
};
